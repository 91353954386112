import React, { ReactElement } from "react";
import SpeedIcon from "../icons/speedIcon";
import FlexibilityIcon from "../icons/flexibility";
import ProductivityIcon from "../icons/productivity";

import BenefitsMain from "../icons/benefitsMain";
import styles from "./benefits.module.scss";

const whyUsList = [
    {
        title: "Speed",
        description:
            "A key feature of copying machines is their impressive speed, often reaching up to 100 pages per minute, while still being cost-effective for businesses of all sizes.",
        icon: <SpeedIcon />,
    },
    {
        title: "Flexibility",
        description:
            "Modern photocopiers go beyond photocopying, acting as scanners and laser printers, improving image quality and streamlining office tasks. This consolidation of functions saves workspace, optimizing your office layout and functionality.",
        icon: <FlexibilityIcon />,
    },
    {
        title: "Productivity",
        description:
            "Adding a photocopier to your office enhances productivity by eliminating the need for external print shops. This in-house solution saves time, reduces the risk of delays, and streamlines office processes, boosting employee efficiency and overall productivity.",
        icon: <ProductivityIcon />,
    },
];

export default function Benefits(): ReactElement {
    return (
        <section
            className={`flex md:flex-row md:justify-between items-center  flex-col max-w-7xl mx-auto px-5 mt-10 mb-16 md:mt-20 gap-10`}
        >
            <div className="flex flex-col w-full md:w-1/2">
                <div className={`${styles["heading"]} md:w-3/4`}>
                    <h2 className="text-2xl font-light md:text-4xl mb-2 md:mb-3">
                        Maximizing Efficiency with a Copying Machine
                    </h2>
                </div>
                <p>
                    A copying machine offers numerous advantages for workplace
                    operations. Let's delve into the key benefits:
                </p>
                <div
                    className={`w-full  mt-10 md:mx-auto  md:flex flex-col md:flex-wrap md:justify-between md:mt-14`}
                >
                    {whyUsList.map((item, index) => (
                        <div
                            key={index}
                            className={`flex flex-col justify-center  mb-10 md:mb-9 ${styles["list-item"]}`}
                        >
                            <div className="flex items-center gap-3 mb-3 ">
                                {item.icon}
                                <h3 className="text-base md:text-lg font-normal mb-1 md:mb-2 leading-4 md:leading-4">
                                    {item.title}
                                </h3>
                            </div>
                            <div className="w-full ">
                                <p className="text-sm font-light">
                                    {item.description}
                                </p>
                            </div>
                        </div>
                    ))}
                </div>
            </div>
            <div className={`w-full md:w-1/2 ${styles.img}`}>
                <BenefitsMain />
            </div>
        </section>
    );
}
