import React from "react";

function ProductivityIcon() {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            width="42"
            height="42"
            fill="none"
            viewBox="0 0 42 42"
        >
            <rect width="42" height="42" fill="#567ED4" rx="8"></rect>
            <mask
                id="mask0_13_711"
                style={{ maskType: "alpha" }}
                width="28"
                height="28"
                x="7"
                y="7"
                maskUnits="userSpaceOnUse"
            >
                <path fill="#D9D9D9" d="M7 7H35V35H7z"></path>
            </mask>
            <g mask="url(#mask0_13_711)">
                <path
                    fill="#fff"
                    d="M12.681 21.118c0 .885.146 1.736.41 2.534l-3.7-2.481 3.655-2.45a8.034 8.034 0 00-.365 2.397zm10.836-7.741L21 9.732l-2.486 3.6a8.375 8.375 0 012.434-.358c.897 0 1.76.142 2.569.403zM18.42 28.873L21 32.61l2.611-3.782a8.363 8.363 0 01-2.664.435c-.881 0-1.73-.138-2.527-.39zm9.532-7.702c0 3.782-3.112 6.85-6.952 6.85s-6.951-3.067-6.951-6.85c0-3.783 3.112-6.85 6.951-6.85 3.84 0 6.952 3.067 6.952 6.85zm-2.1 0a.674.674 0 00-.678-.67h-3.491v-4.209a.674.674 0 00-.679-.669c-.375 0-.679.3-.679.67v4.878c0 .369.304.669.68.669h4.169c.375 0 .678-.3.678-.67zm6.758 0l-3.79-2.54a8.022 8.022 0 01-.048 5.112l3.838-2.572z"
                ></path>
            </g>
        </svg>
    );
}

export default ProductivityIcon;
