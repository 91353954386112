import React from "react";

function SpeedIcon() {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            width="42"
            height="42"
            fill="none"
            viewBox="0 0 42 42"
        >
            <rect width="42" height="42" fill="#567ED4" rx="8"></rect>
            <mask
                id="mask0_13_710"
                style={{ maskType: "alpha" }}
                width="25"
                height="25"
                x="8"
                y="9"
                maskUnits="userSpaceOnUse"
            >
                <path fill="#fff" d="M8 9H33V34H8z"></path>
            </mask>
            <g mask="url(#mask0_13_710)">
                <path
                    fill="#fff"
                    d="M29.646 21.347l-.995-1.176-7.143-8.427-2.706 2.351 6.148 7.252-6.148 7.253 2.706 2.351 7.143-8.428.995-1.176zm-7.143 0l-.995-1.176-7.143-8.427-2.707 2.351 6.149 7.252-6.148 7.253 2.706 2.351 7.143-8.428.995-1.176z"
                ></path>
            </g>
        </svg>
    );
}

export default SpeedIcon;
