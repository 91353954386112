import React from "react";

function BenefitsMain() {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            width="687"
            height="692"
            fill="none"
            viewBox="0 0 687 692"
        >
            <g clipPath="url(#clip0_1_381)">
                <path
                    fill="#5367F5"
                    d="M343.5 692C533.21 692 687 603.082 687 493.396c0-109.685-153.79-198.603-343.5-198.603S0 383.711 0 493.396C0 603.082 153.79 692 343.5 692z"
                ></path>
                <path
                    fill="#E1E5FF"
                    d="M145.82 444.17c0 17.48 35.989 43.865 43.923 21.804 9.43-26.227 55.597-15.163 59.798-29.637 3.206-11.047-24.829-21.022-52.644-22.181-27.816-1.159-51.069 14.939-51.077 30.014z"
                ></path>
                <path
                    fill="#EDC1A6"
                    d="M246.937 193.47l-27.011-47.32 13.532-8.709 24.145 42.594-10.666 13.435z"
                ></path>
                <path
                    fill="#BFCCD9"
                    d="M230.277 176.173c-2.015-3.177-18.733-30.072-18.529-41.751.116-6.715 3.168-8.954 7.49-10.179 3.699-1.051 8.559.133 11.562 3.559 3.43 3.908 17.63 29.803 20.516 34.986 0 0-1.339 4.128-4.703 6.611-3.861 2.849-16.336 6.774-16.336 6.774z"
                ></path>
                <path
                    fill="#616B8C"
                    d="M182.684 453.768c1.626 3.372 1.456 1.906 4.114 7.633 1.497 3.223 2.455 8.468-4.653 9.299-4.562.536-9.203-1.603-12.122-4.049-1.904-1.595-4.977-5.246-6.333-10.483-.282-1.096-3.16-3.862-4.272-6.171-1.111-2.309-4.147-8.722 3.919-12.239 8.067-3.518 13.417 5.868 14.694 7.949 1.219 1.985 2.289 3.164 4.653 8.061z"
                ></path>
                <path
                    fill="#2F384F"
                    d="M158.684 439.805c1.244-3.397-.149-5.266 2.986-5.515 8.867-.698 14.296 3.991 15.187 6.2.892 2.21 1.332.524 4.172 8.481.983 2.749 2.111 5.27 3.351 6.923a16.572 16.572 0 012.211 3.85c.979 2.617 2.488 6.487-2.406 8.182-4.997 1.736-10.339-1.109-13.159-2.795-2.459-1.471-5.839-5.399-7.95-10.67-.415-1.05-3.848-6.204-4.466-9.319-.15-.76-.834-3.136.074-5.337z"
                ></path>
                <path
                    fill="#616B8C"
                    d="M220.934 435.058c4.694 1.828 6.664 2.957 12.217 3.568 2.721.299 11.874 1.246 14.735-2.048.519-.594.44-3.347.295-3.991-.585-2.492-5.031-2.965-6.806-3.713-7.494-3.16-7.15-1.474-14.785-3.272-1.41-.333-9.032-2.33-12.354-2.675-2.206-.228-10.513 1.383-10.683 1.902-1.696 5.262 3.16 6.508 6.071 7.301 2.783.756 5.947.839 11.31 2.928z"
                ></path>
                <path
                    fill="#2F384F"
                    d="M203.81 415.825c.116-.83.336-1.245.601-1.453 1.738-1.42 5.665 3.015 11.098 5.058 4.11 1.549 5.582-4.29 8.759-1.765a37.41 37.41 0 0010.7 6.807c.502.199 6.08 1.886 6.254 1.948 4.18 1.499 8.539 4.722 6.507 7.708-2.671 3.933-12.027 2.434-14.731 2.043-3.368-.486-5.483-.83-12.218-3.567-5.329-2.176-8.526-2.168-11.305-2.907-2.206-.603-5.947-1.587-6.092-3.809a12.66 12.66 0 01-.56-4.115c.261-3.456.817-4.922.987-5.948z"
                ></path>
                <path
                    fill="#37415E"
                    d="M195.01 256.215c1.522-2.945 8.261-8.111 11.239-9.486 5.076-2.334 16.837-6.861 17.957-.652 3.056 16.99 5.022 28.299 6.71 47.47 1.539 17.58 1.879 39.35.581 50.123-.768 10.325-8.369 65.652-7.532 73.306.179 1.661.311 5.253-9.783 4.714-4.271-.229-8.004-4.029-8.668-5.25-1.356-2.492 2.074-67.807 2.252-72.263-.904-11.052-3.865-23.777-7.946-34.641-3.844-10.246-8.352-18.689-9.853-28.465-.88-5.619-.987-13.207 5.043-24.856z"
                ></path>
                <path
                    fill="#616B8C"
                    d="M161.003 348.907c-3.762-39.869-6.76-80.827-2.796-88.198 5.197-9.665 15.573-11.882 18.219-12.273 4.408-.648 26.882-8.455 26.447 5.914-.092 2.965 1.882 20.641-1.004 21.908-14.154 6.229-14.038 60.722-15.602 74.53-.829 20.899-8.186 54.173-8.294 89.943 0 2.442.249 3.381-2.003 4.07-1.871.573-6.287 1.603-12.326-2.255a7.66 7.66 0 01-3.504-6.076s1.223-71.557.863-87.563z"
                ></path>
                <path
                    fill="#F7F9FC"
                    d="M231.235 166.878c1.526-13.534.627-31.642-4.213-38.474-1.779-2.491-3.372-3.488-5.773-4.448-2.074-.83-27.206 4.029-35.707 8.46-12.442 6.492-27.671 24.624-30.785 30.828-1.941 3.904-3.65 18.772-3.318 37.316.382 21.18 5.868 56.178 7.465 58.014 3.098 3.621 14.196 4.315 14.196 4.315 30.975 1.711 48.107-9.527 51.749-17.966.414-1.009 3.147-14.536 4.62-33.935.311-4.319.991-37.191 1.766-44.11z"
                ></path>
                <path
                    fill="#EDC1A6"
                    d="M180.32 135.477c-.037 1.317 1.199 2.691 1.622 3.086 1.829 1.711 8.116 4.801 15.962 2.84 4.371-1.104 4.998-4.427 4.998-4.427l.186-7.247-24.468-21.014s1.729 19.079 1.721 22.252c.004 1.769.021 3.102-.021 4.51z"
                ></path>
                <path
                    fill="#FBD5BF"
                    d="M217.073 87.065c1.493 2.907 1.48 6.113 1.215 11.263-.05.868.539 10.312.22 12.496-1.68 11.496-6.084 18.81-8.506 19.719-3.194 1.205-6.835 2.056-11.579.042-2.377-1.014-9.124-5.063-11.944-7.06-1.738-1.213-3.193-7.941-3.803-9.868-.394-1.246-.56 1.362-2.202 1.362a2.199 2.199 0 01-1.983-1.096c-11.035-15.541-10.007-37.116 1.962-41.871 11.969-4.756 31.701 5.481 36.62 15.013z"
                ></path>
                <path
                    fill="#2F384F"
                    d="M213.282 78.58c1.904 1.728 3.073 3.738 5.889.806.863-.901 3.534 6.86-3.284 8.485-1.941.46-4.562 1.706-10.887 1.885-.921.025-9.418-.594-11.542-.88a1.964 1.964 0 00-1.194.178c-.327.183-.599.45-.788.773-.995 1.62-.73 4.713-.945 5.037-1.211 1.873-3.961 2.172-3.936 4.722 0 .416.415 7.933-5.985 6.803 1.245-.831 2.116-2.442.975-5.063-.887-2.076-1.966-3.467-3.533-3.737-2.903-.499-3.127 4.153-1.974 8.543 1.323 5.079 3.185 8.372 2.073 8.065-5.192-1.441-9.02-5.968-6.366-6.346 3.94-.557-6.897-14.237-.696-28.37 7.464-17.01 30.826-11.242 42.193-.9z"
                ></path>
                <path
                    fill="#E6EDF7"
                    d="M190.833 195.626c3.231-.307 5.392-1.043 8.461-2.712 1.833-1.005 2.903-3.771 3.002-5.852.191-3.679-.589-5.814-.742-9.705-4.425 2.62-11.509 3.737-18.351 5.482-.15 2.682-.1 6.117.676 8.692 1.053 3.497 2.616 4.44 6.954 4.095z"
                ></path>
                <path
                    fill="#fff"
                    d="M142.747 98.249l-6.35-3.322a.624.624 0 01-.253-.254l-5.134-9.801a.602.602 0 01.232-.802l3.098-1.777a.605.605 0 01.675.044.6.6 0 01.155.18l8.389 14.901a.602.602 0 01-.522.897.624.624 0 01-.29-.066zm-10.406-13.423l4.782 9.104 4.379 2.305-7.091-12.597-2.07 1.188zM115.023 83.335c-1.245-.73-1.95-2.205-1.954-4.153l-.099-34.404c0-3.787 2.658-8.418 5.951-10.32L176.982.88c1.717-.992 3.359-1.133 4.632-.415l8 4.531.066.038-.597 1.042-.054-.03c-.879-.48-2.073-.331-3.384.416l-58.061 33.573c-2.961 1.712-5.358 5.873-5.349 9.279l.099 34.412c0 1.507.485 2.616 1.352 3.123l-.597 1.059-8.066-4.573zM181.025 1.52c-.883-.515-2.102-.374-3.429.415l-58.061 33.578c-2.957 1.71-5.358 5.868-5.35 9.274l.099 34.416c0 1.508.486 2.612 1.348 3.115l5.752 3.26a6.679 6.679 0 01-.236-1.807l-.112-34.424c0-3.788 2.658-8.419 5.951-10.32l58.061-33.574a6.717 6.717 0 011.659-.702l-5.682-3.231z"
                ></path>
                <path
                    fill="#fff"
                    d="M142.954 97.938l-8.527-14.295-6.706 3.874c-1.708.989-3.355 1.13-4.624.416-1.269-.715-1.957-2.206-1.961-4.153l-.1-34.433c0-3.788 2.658-8.419 5.951-10.32l58.061-33.574c1.713-.993 3.355-1.134 4.624-.415 1.269.718 1.962 2.205 1.966 4.153l.095 34.416c0 3.788-2.658 8.418-5.947 10.32l-36.284 20.978-5.453 22.841a.607.607 0 01-.494.453h-.091a.6.6 0 01-.51-.261zm47.49-88.739c0-1.512-.49-2.62-1.361-3.127-.871-.507-2.098-.366-3.425.415L127.597 40.06c-2.961 1.712-5.358 5.873-5.35 9.278l.1 34.413c0 1.511.485 2.624 1.36 3.127.875.502 2.073.365 3.421-.415l7.216-4.174a.607.607 0 01.673.035.608.608 0 01.157.177l8.074 13.539 5.139-21.663a.59.59 0 01.282-.382l36.495-21.106c2.957-1.71 5.358-5.872 5.346-9.274L190.415 9.2h.029z"
                ></path>
                <path
                    fill="#fff"
                    d="M138.359 59.85c-.892-.515-1.385-1.512-1.385-2.803 0-2.688 2.073-5.997 4.74-7.534 1.481-.856 2.924-1.001 3.961-.416.891.515 1.381 1.512 1.381 2.804 0 2.687-2.074 5.997-4.741 7.533a5.11 5.11 0 01-2.521.769 2.817 2.817 0 01-1.435-.353zm3.956-9.295c-2.243 1.3-4.147 4.27-4.147 6.492 0 .598.133 1.387.78 1.76.647.374 1.659.233 2.754-.415 2.243-1.296 4.147-4.27 4.147-6.491 0-.598-.137-1.387-.78-1.761-.254-.14-.54-.21-.829-.204a3.99 3.99 0 00-1.925.62zM152.733 51.577c-.892-.515-1.381-1.512-1.381-2.803 0-2.688 2.074-5.997 4.736-7.534 1.481-.856 2.903-1.001 3.961-.415.891.515 1.381 1.511 1.381 2.803 0 2.687-2.074 5.997-4.741 7.534a5.15 5.15 0 01-2.521.764 2.845 2.845 0 01-1.435-.35zm3.948-9.29c-2.243 1.295-4.147 4.269-4.147 6.49 0 .599.133 1.388.78 1.762.647.373 1.659.228 2.753-.416 2.281-1.32 4.148-4.232 4.148-6.49 0-.599-.137-1.388-.78-1.762a1.66 1.66 0 00-.83-.208 3.98 3.98 0 00-1.924.623zM167.107 43.304c-.891-.52-1.385-1.512-1.385-2.808 0-2.687 2.074-5.992 4.74-7.529 1.481-.855 2.903-1.005 3.961-.415.892.519 1.381 1.511 1.381 2.807 0 2.687-2.074 5.993-4.74 7.53a5.114 5.114 0 01-2.522.768 2.854 2.854 0 01-1.435-.353zm3.957-9.295c-2.244 1.296-4.148 4.27-4.148 6.487 0 .599.133 1.392.78 1.766.647.373 1.659.228 2.754-.416 2.243-1.295 4.147-4.27 4.147-6.487 0-.598-.137-1.391-.78-1.76a1.63 1.63 0 00-.829-.208 3.978 3.978 0 00-1.924.618z"
                ></path>
                <path
                    fill="#E1E5FF"
                    d="M401.619 547.266l-54.88-155.939-125.436 72.524 143.103 82.742 37.213.673z"
                ></path>
                <path
                    fill="#616B8C"
                    d="M401.619 547.266l-143.103-82.733v-33.827l3.309-1.043-3.305-2.035v-36.97l143.103 82.737-.004 73.871z"
                ></path>
                <path
                    fill="#3D4663"
                    d="M527.059 400.87l-143.103-82.741-125.436 72.525 143.103 82.741 125.436-72.525z"
                ></path>
                <path
                    fill="#7E87A8"
                    d="M527.059 474.741l.004-74.286-125.44 72.94-.004 73.871 10.924-6.313v-1.578l2.173.32 112.343-64.954z"
                ></path>
                <path
                    fill="#BFCCD9"
                    d="M401.619 470.177l-143.103-82.738.004-163.464 143.103 82.737-.004 163.465z"
                ></path>
                <path
                    fill="#F7F9FC"
                    d="M527.059 234.187l-143.103-82.741-112.588 65.099.016 2.525-2.347-1.18-10.517 6.081 143.103 82.741 125.436-72.525z"
                ></path>
                <path
                    fill="#E6EDF7"
                    d="M527.059 397.652V234.187l-125.436 72.525-.004 163.465 10.642-6.155v-1.761l2.173.507 112.625-65.116z"
                ></path>
                <path
                    fill="#F7F9FC"
                    d="M431.761 131.897L288.657 49.16l2.584-1.898a7.895 7.895 0 018.631-.47l142.016 83.992-10.127 1.113z"
                ></path>
                <path
                    fill="#BFCCD9"
                    d="M527.059 234.187l-143.103-82.708-95.299-102.323 143.104 82.712 95.298 102.319z"
                ></path>
                <path
                    fill="#E6EDF7"
                    d="M432.918 131.046l-1.157.83L527.059 234.2l1.398-2.002a8.687 8.687 0 00-.805-10.944l-84.354-89.29a7.899 7.899 0 00-10.38-.918z"
                ></path>
                <path
                    fill="#3D4663"
                    d="M382.533 506.08v-34.471a.75.75 0 01.75-.75c.132 0 .261.036.374.103l10.086 5.814a.743.743 0 01.374.648v34.445a.75.75 0 01-1.124.652l-10.086-5.814a.758.758 0 01-.374-.627z"
                ></path>
                <path
                    fill="#7E87A8"
                    d="M392.64 510.648l-8.634-4.988 8.634.76v4.228z"
                ></path>
                <path
                    fill="url(#paint0_linear_1_381)"
                    d="M392.64 506.42l-8.634-.76v-32.776l8.634 4.992v28.544z"
                ></path>
                <path
                    fill="#3D4663"
                    d="M349.675 517.272l-2.559-1.478V440.96l2.559 2.433v73.879z"
                ></path>
                <path
                    fill="#3D4663"
                    d="M349.476 483.338l-90.96-52.632 3.309-1.043 87.647 50.738.004 2.937z"
                ></path>
                <path
                    fill="#2A3145"
                    d="M336.255 465.272l-69.254-40.044v-9.386l69.254 40.036v9.394z"
                ></path>
                <path
                    fill="#7E87A8"
                    d="M269.468 424.182l-2.467 1.046 69.254 40.044v-2.479l-66.787-38.611z"
                ></path>
                <path
                    fill="#3D4663"
                    d="M269.468 417.267l-2.467-1.425v9.386l2.467-1.047v-6.914z"
                ></path>
                <path
                    fill="#2A3145"
                    d="M336.255 501.748l-69.254-40.04v-9.39l69.254 40.04v9.39z"
                ></path>
                <path
                    fill="#7E87A8"
                    d="M269.468 460.658l-2.467 1.05 69.254 40.04v-2.475l-66.787-38.615z"
                ></path>
                <path
                    fill="#3D4663"
                    d="M269.468 453.747l-2.467-1.429v9.39l2.467-1.05v-6.911zM372.431 353.256v-47.399a1.188 1.188 0 01.596-1.038 1.197 1.197 0 011.196-.001l13.685 7.891a1.205 1.205 0 01.597 1.039v47.407a1.19 1.19 0 01-.597 1.034 1.195 1.195 0 01-1.194 0l-13.686-7.891a1.19 1.19 0 01-.597-1.042z"
                ></path>
                <path
                    fill="#7E87A8"
                    d="M386.444 359.68l-11.973-6.919 11.973 1.055v5.864z"
                ></path>
                <path
                    fill="url(#paint1_linear_1_381)"
                    d="M386.444 353.816l-11.973-1.055v-45.434l11.973 6.919v39.57z"
                ></path>
                <path
                    fill="#3D4663"
                    d="M272.04 386.04l.004-130.868 63.908 36.95v130.867L272.04 386.04z"
                ></path>
                <path
                    fill="#B0B0B0"
                    d="M441.801 360.868l-97.841 56.569-61.498-35.563 97.836-56.565 61.503 35.559z"
                ></path>
                <path
                    fill="#B2B2B2"
                    d="M441.801 358.231L343.96 414.8l-61.498-35.559 97.836-56.565 61.503 35.555z"
                ></path>
                <path
                    fill="#B4B4B4"
                    d="M441.801 355.598l-97.841 56.569-61.498-35.559 97.836-56.569 61.503 35.559z"
                ></path>
                <path
                    fill="#B7B7B7"
                    d="M441.801 352.965l-97.841 56.569-61.498-35.559 97.836-56.569 61.503 35.559z"
                ></path>
                <path
                    fill="#B9B9B9"
                    d="M441.801 350.332l-97.841 56.569-61.498-35.559 97.836-56.569 61.503 35.559z"
                ></path>
                <path
                    fill="#BBB"
                    d="M441.801 347.699l-97.841 56.568-61.498-35.562 97.836-56.565 61.503 35.559z"
                ></path>
                <path
                    fill="#BDBDBD"
                    d="M441.801 345.066l-97.841 56.568-61.498-35.562 97.836-56.565 61.503 35.559z"
                ></path>
                <path
                    fill="#BFBFBF"
                    d="M441.801 342.428l-97.841 56.569-61.498-35.558 97.836-56.565 61.503 35.554z"
                ></path>
                <path
                    fill="#C2C2C2"
                    d="M441.801 339.795l-97.841 56.569-61.498-35.558 97.836-56.569 61.503 35.558z"
                ></path>
                <path
                    fill="#C4C4C4"
                    d="M441.801 337.162l-97.841 56.569-61.498-35.558 97.836-56.569 61.503 35.558z"
                ></path>
                <path
                    fill="#C6C6C6"
                    d="M441.801 334.529l-97.841 56.569-61.498-35.558 97.836-56.569 61.503 35.558z"
                ></path>
                <path
                    fill="#C8C8C8"
                    d="M441.801 331.896l-97.841 56.569-61.498-35.563 97.836-56.564 61.503 35.558z"
                ></path>
                <path
                    fill="#CACACA"
                    d="M441.801 329.263l-97.841 56.565-61.498-35.559 97.836-56.564 61.503 35.558z"
                ></path>
                <path
                    fill="#CDCDCD"
                    d="M441.801 326.63l-97.841 56.565-61.498-35.559 97.836-56.564 61.503 35.558z"
                ></path>
                <path
                    fill="#CFCFCF"
                    d="M441.801 323.993l-97.841 56.569-61.498-35.559 97.836-56.569 61.503 35.559z"
                ></path>
                <path
                    fill="#D1D1D1"
                    d="M441.801 321.36l-97.841 56.569-61.498-35.559 97.836-56.569 61.503 35.559z"
                ></path>
                <path
                    fill="#D3D3D3"
                    d="M441.801 318.727l-97.841 56.569-61.498-35.559 97.836-56.569 61.503 35.559z"
                ></path>
                <path
                    fill="#D5D5D5"
                    d="M441.801 316.094l-97.841 56.569-61.498-35.559 97.836-56.569 61.503 35.559z"
                ></path>
                <path
                    fill="#D7D7D7"
                    d="M441.801 313.461L343.96 370.03l-61.498-35.563 97.836-56.565 61.503 35.559z"
                ></path>
                <path
                    fill="#DADADA"
                    d="M441.801 310.824l-97.841 56.568-61.498-35.558 97.836-56.565 61.503 35.555z"
                ></path>
                <path
                    fill="#DCDCDC"
                    d="M441.801 308.191l-97.841 56.568-61.498-35.558 97.836-56.565 61.503 35.555z"
                ></path>
                <path
                    fill="#DEDEDE"
                    d="M441.801 305.557l-97.841 56.569-61.498-35.558 97.836-56.569 61.503 35.558z"
                ></path>
                <path
                    fill="#E0E0E0"
                    d="M441.801 302.924l-97.841 56.569-61.498-35.558 97.836-56.569 61.503 35.558z"
                ></path>
                <path
                    fill="#E2E2E2"
                    d="M441.801 300.291L343.96 356.86l-61.498-35.562 97.836-56.565 61.503 35.558z"
                ></path>
                <path
                    fill="#E5E5E5"
                    d="M441.801 297.658l-97.841 56.569-61.498-35.562 97.836-56.565 61.503 35.558z"
                ></path>
                <path
                    fill="#E7E7E7"
                    d="M441.801 295.025l-97.841 56.569-61.498-35.563 97.836-56.564 61.503 35.558z"
                ></path>
                <path
                    fill="#E9E9E9"
                    d="M441.801 292.388l-97.841 56.569-61.498-35.559 97.836-56.568 61.503 35.558z"
                ></path>
                <path
                    fill="#EBEBEB"
                    d="M441.801 289.755l-97.841 56.569-61.498-35.559 97.836-56.568 61.503 35.558z"
                ></path>
                <path
                    fill="#EDEDED"
                    d="M441.801 287.122l-97.841 56.569-61.498-35.559 97.836-56.569 61.503 35.559z"
                ></path>
                <path
                    fill="#F0F0F0"
                    d="M441.801 284.489l-97.841 56.569-61.498-35.559 97.836-56.569 61.503 35.559z"
                ></path>
                <path
                    fill="#F2F2F2"
                    d="M441.801 281.856l-97.841 56.569-61.498-35.563 97.836-56.565 61.503 35.559z"
                ></path>
                <path
                    fill="#F4F4F4"
                    d="M441.801 279.223l-97.841 56.565-61.498-35.559 97.836-56.565 61.503 35.559z"
                ></path>
                <path
                    fill="#F6F6F6"
                    d="M441.801 276.586l-97.841 56.569-61.498-35.559 97.836-56.565 61.503 35.555z"
                ></path>
                <path
                    fill="#F8F8F8"
                    d="M441.801 273.953l-97.841 56.569-61.498-35.559 97.836-56.569 61.503 35.559z"
                ></path>
                <path
                    fill="#FBFBFB"
                    d="M441.801 271.32l-97.841 56.568-61.498-35.558 97.836-56.569 61.503 35.559z"
                ></path>
                <path
                    fill="#FDFDFD"
                    d="M441.801 268.687l-97.841 56.568-61.498-35.562 97.836-56.565 61.503 35.559z"
                ></path>
                <path
                    fill="#fff"
                    d="M441.801 266.054l-97.841 56.568-61.498-35.562 97.836-56.565 61.503 35.559z"
                ></path>
                <path
                    fill="#498080"
                    d="M280.243 265.472l-3.393-1.96-.004 119.749 3.393-1.698.004-116.091z"
                ></path>
                <path
                    fill="#73CACA"
                    d="M280.239 381.563l-3.393 1.698 54.3 31.389v-3.659l-50.907-29.428z"
                ></path>
                <path
                    fill="#5367F5"
                    d="M331.146 414.65l-54.3-31.389.004-119.749 54.296 31.389V414.65z"
                    opacity="0.4"
                ></path>
                <path
                    fill="#7E87A8"
                    d="M415.516 291.549l-129.919-75.116 97.625-56.54 129.919 75.121-97.625 56.535z"
                ></path>
                <path
                    fill="#3D4663"
                    d="M383.222 159.893v6.678l124.092 71.728 5.827-3.285-129.919-75.121z"
                ></path>
                <path
                    fill="#242A3B"
                    d="M385.304 165.371v-1.869l123.636 71.487-1.613.909-122.023-70.527zM403.439 280.465s-.344-2.833.262-5.038a12.71 12.71 0 015.806-7.513l-3.339 11.051-2.729 1.5z"
                ></path>
                <path
                    fill="#3D4663"
                    d="M504.735 230.113l-97.563 56.573-7.664-4.39 5.864-3.38s-1.062-2.314-.829-4.594c.414-4.02 4.976-6.478 4.976-6.478l66.314-38.009s4.334-2.222 8.493-2.193c2.874.021 7.826 1.001 7.826 1.001l5.064-2.928 7.519 4.398z"
                ></path>
                <path
                    fill="#616B8C"
                    d="M285.597 216.433l5.843 3.376 91.782-53.238v-6.678l-97.625 56.54z"
                ></path>
                <path
                    fill="#BFCCD9"
                    d="M404.174 280.672c-1.987 1.147-2.294 2.845-.685 3.775 1.609.931 4.562.748 6.52-.415l90.218-52.328c1.982-1.147 2.335-2.854.784-3.788-1.551-.934-4.442-.764-6.424.382l-90.413 52.374z"
                ></path>
                <path
                    fill="#CFD9E3"
                    d="M404.555 280.867c-1.887 1.093-2.098 2.596-.796 3.364 1.302.769 3.832.669 5.686-.415l90.218-52.328c1.982-1.151 2.227-2.571.97-3.356-1.256-.785-3.678-.785-5.661.361l-90.417 52.374z"
                ></path>
                <path
                    fill="#DFE6EC"
                    d="M404.941 281.063c-1.792 1.038-1.912 2.338-.913 2.953.917.564 3.123.585 4.857-.416l90.214-52.328c1.982-1.147 2.123-2.285 1.161-2.908-.879-.581-2.903-.805-4.902.341l-90.417 52.358z"
                ></path>
                <path
                    fill="#EFF2F6"
                    d="M405.326 281.254c-1.7.984-1.721 2.076-1.032 2.541.58.387 2.418.507 4.027-.415l90.218-52.328c1.978-1.151 2.011-1.998 1.344-2.492-.54-.416-2.157-.831-4.148.319l-90.409 52.375z"
                ></path>
                <path
                    fill="#fff"
                    d="M405.712 281.445c-1.605.93-1.526 1.823-1.149 2.134.241.196 1.709.416 3.194-.415l90.239-52.328c1.978-1.151 1.895-1.699 1.534-2.052-.216-.212-1.398-.831-3.384.299l-90.434 52.362z"
                ></path>
                <path
                    fill="#242A3B"
                    d="M496.465 238.498c-3.894 2.882-19.599 12.509-19.599 12.509s-49.767 29.275-56.278 32.045c-1.659.706-5.059.773-5.059.773l-6.81 3.737-1.539-.888 97.555-56.574-.49 2.168-5.806 3.277a5.612 5.612 0 01-1.974 2.953z"
                ></path>
                <path
                    fill="#3D4663"
                    d="M415.931 283.476a4.786 4.786 0 002.165-.415c14.187-7.555 65.895-37.581 79.211-46.099.328-.212.85-1.309.85-1.309l-82.226 47.823z"
                ></path>
                <path
                    fill="url(#paint2_linear_1_381)"
                    d="M513.141 235.014l-69.83-40.377c-17.236 14.258-57.443 52.811-57.443 76.944 0 .96-.224 2.11-.191 3.061l29.86 16.907 97.604-56.535z"
                    opacity="0.2"
                ></path>
                <path
                    fill="#5367F5"
                    d="M415.516 291.549l-129.919-75.116 97.625-56.54 129.919 75.121-97.625 56.535z"
                    opacity="0.4"
                ></path>
                <path
                    fill="#3D4663"
                    d="M414.716 539.695l-2.173-.32v-73.019l2.173-.573v73.912zM414.434 462.768l-2.173 1.254V300.208l2.173-.951v163.511z"
                ></path>
                <path
                    fill="#3D4663"
                    d="M413.086 300.997L271.384 219.07l-.016-2.525 143.066 82.712-1.348 1.74z"
                ></path>
                <path
                    fill="#FBD5BF"
                    d="M174.427 218.53c8.27.515 17.858-.98 32.879-2.907 11.197-1.445 15.208-4.095 20.346-4.622 0 0 1.124-.221 1.916-.437 3.015-.83 3.695-2.969 6.3-5.399a15.185 15.185 0 012.513-2.491c1.07-.802 4.089-1.662 5.42-2.01 3.165-.868 5.11-2.276 4.749-.582-.344 1.607-.34 2.342-3.115 4.257-.298.212-3.421 1.599-3.703 1.873 1.659.577 4.79.191 6.183-.374 3.355-1.362 11.505-3.8 10.659-.394-.307 1.246-2.609 7.774-4.048 9.926-1.439 2.151-5.026 5.556-6.839 6.022-5.777 1.478-8.966 2.076-11.197 2.039-1.738-.029-3.782-1.408-6.524-.515-3.861 1.345-11.027 3.567-23.477 6.848-15.61 4.12-39.813 10.985-41.833-1.424l9.771-9.81z"
                ></path>
                <path
                    fill="#FBD5BF"
                    d="M164.64 228.344c-.569-1.786-1.332-7.368-1.332-7.368l-6.627-42.253 17.796-3.115 5.428 42.216c.271 1.507.62 2.998 1.046 4.469l-16.311 6.051z"
                ></path>
                <path
                    fill="#E6EDF7"
                    d="M158.195 209.46c-.523-1.508-5.777-33.989-5.64-38.769.236-8.007 3.757-12.908 6.751-14.723 2.592-1.578 5.985-1.707 9.676 0 4.603 2.147 6.461 6.944 8.385 13.099 2.323 7.43 6.4 29.2 7.146 34.637 0 0-3.62 5.868-14.283 7.654-7.743 1.312-12.035-1.898-12.035-1.898z"
                ></path>
                <path
                    fill="#fff"
                    d="M441.888 211.835l-72.053 41.66-49.265-28.482 72.054-41.659 49.264 28.481z"
                ></path>
                <path
                    fill="#E6EDF7"
                    d="M407.23 208.409a1.204 1.204 0 01-.585-.158l-22.61-13.074a1.177 1.177 0 011.174-2.039l22.61 13.074a1.176 1.176 0 01-.589 2.197zM415.242 222.691a1.17 1.17 0 01-.605-.162l-38.984-22.53a1.178 1.178 0 01.282-2.156c.301-.081.622-.039.892.117l38.984 22.53a1.18 1.18 0 01.553 1.327 1.17 1.17 0 01-1.142.87l.02.004zM406.898 227.513a1.22 1.22 0 01-.589-.158l-38.983-22.53a1.164 1.164 0 01-.548-.714 1.18 1.18 0 01.419-1.239 1.171 1.171 0 011.302-.086l38.984 22.53a1.176 1.176 0 01-.589 2.197h.004zM398.554 232.339a1.15 1.15 0 01-.585-.158l-38.983-22.526a1.178 1.178 0 01.282-2.156c.301-.081.621-.039.891.117l38.984 22.526a1.176 1.176 0 01-.589 2.197zM390.21 237.165a1.144 1.144 0 01-.585-.158l-38.983-22.53a1.179 1.179 0 01.739-2.187c.153.02.3.07.434.147l38.984 22.531a1.17 1.17 0 01.553 1.326 1.176 1.176 0 01-1.142.871zM381.874 241.987a1.16 1.16 0 01-.585-.158l-38.983-22.527a1.18 1.18 0 01-.432-1.607 1.178 1.178 0 011.605-.432l38.984 22.527a1.17 1.17 0 01.553 1.326 1.18 1.18 0 01-.422.631c-.207.157-.46.241-.72.24z"
                ></path>
                <path
                    fill="#EDC1A6"
                    d="M257.603 180.039c4.127 1.965 10.107 4.843 15.009 6.853 7.204 2.945 30.494 14.39 35.96 16.168l.038-.037.286.141c.262.074.51.192.734.349.253.116.518.236.759.336 2.903 1.184 7.465 2.583 11.753 4.261 5.599 2.193 10.285 6.117 12.819 8.306 1.709 1.462 3.417 6.915 1.709 8.859-1.568 1.785-1.95 6.499-16.311-1.844-1.958-1.134-3.243-2.147-4.666-1.487-2.439 1.129 5.528 5.71 4.338 8.231-.925 1.96-4.923.523-11.09-5.814-.331-.345-1.327-5.598-1.658-6.03-.751-.964-1.858-3.281-3.268-4.045-4.716-1.155-17.726-5.507-25.149-7.567-11.612-3.223-25.63-7.796-31.934-13.219l10.671-13.461z"
                ></path>
                <path
                    fill="#E1E5FF"
                    d="M120.7 544.064c-11.774-6.807-27.993-9.444-43.189-7.92-11.761-6.645-38.917 7.347-51.09-3.867-5.208-4.801-5.295 8.697-2.807 12.92 6.793 11.542 16.075 5.649 18.55 6.151-9.273 10.694-6.175 24.088 9.282 33.021 19.251 11.13 50.359 11.13 69.482 0 19.123-11.13 19.027-29.179-.228-40.305z"
                ></path>
                <path
                    fill="url(#paint3_linear_1_381)"
                    d="M138.396 513.692c-19.123 11.126-50.23 11.126-69.482 0-9.688-5.598-14.515-12.949-14.515-20.292l-.154 63.073c-.02 7.338 4.815 14.689 14.515 20.292 19.252 11.13 50.36 11.13 69.483 0 9.501-5.528 14.258-12.767 14.278-20.014l.179-63.072c-.046 7.247-4.803 14.481-14.304 20.013z"
                ></path>
                <path
                    fill="#F7F9FC"
                    d="M138.168 473.374c-19.247-11.126-50.355-11.126-69.482 0-19.127 11.126-19.023 29.176.228 40.285 19.251 11.11 50.359 11.13 69.482 0 19.123-11.13 19.027-29.142-.228-40.285z"
                ></path>
                <path
                    fill="#fff"
                    d="M68.914 513.688a61.254 61.254 0 0014.432 5.453 79.785 79.785 0 0015.241 2.214c2.567.116 5.134.124 7.697.041a80.36 80.36 0 007.669-.643 72.051 72.051 0 0014.929-3.477 49.09 49.09 0 0013.512-7.06 29.52 29.52 0 005.458-5.253c.373-.511.779-.997 1.107-1.533l.518-.793.461-.831.232-.415.203-.415.415-.831.34-.876.17-.44.137-.453c.379-1.198.621-2.435.722-3.688.086-1.257.037-2.52-.146-3.767a18.982 18.982 0 00-2.708-7.06 27.707 27.707 0 00-4.927-5.868 43.073 43.073 0 00-6.22-4.61c4.537 2.454 8.754 5.743 11.699 10.117a19.584 19.584 0 012.998 7.267c.225 1.313.307 2.645.245 3.975a17.569 17.569 0 01-.668 3.929 20.882 20.882 0 01-3.637 7.039 30.782 30.782 0 01-5.615 5.553 44.06 44.06 0 01-6.636 4.199 54.464 54.464 0 01-7.199 3.106 72.466 72.466 0 01-15.233 3.472 81.541 81.541 0 01-15.59.361 79.34 79.34 0 01-15.344-2.616 57.984 57.984 0 01-14.262-6.097z"
                ></path>
                <path
                    fill="url(#paint4_linear_1_381)"
                    d="M145.372 493.621a10.342 10.342 0 01-1.199 4.722c-1.63 3.227-4.881 6.37-9.443 9.024-8.037 4.677-19.342 7.359-31.025 7.359s-23.046-2.682-31.146-7.371c-4.674-2.7-7.995-5.902-9.65-9.195a10.392 10.392 0 01-1.199-4.701c0-4.797 3.87-9.814 10.646-13.751 8.033-4.677 19.347-7.359 31.03-7.359 11.682 0 23.037 2.687 31.137 7.371 6.888 3.979 10.84 9.046 10.849 13.901z"
                ></path>
                <path
                    fill="#323952"
                    d="M144.173 498.343c-1.63 3.227-4.881 6.37-9.443 9.024-8.037 4.677-19.343 7.359-31.025 7.359-11.683 0-23.046-2.682-31.146-7.371-4.674-2.7-7.995-5.902-9.65-9.195 1.634-3.235 4.881-6.383 9.447-9.033 8.033-4.676 19.347-7.359 31.029-7.359 11.683 0 23.038 2.683 31.138 7.363 4.682 2.712 8.004 5.914 9.65 9.212z"
                ></path>
                <path
                    fill="#FF7A00"
                    d="M105.003 502.002c-1.286-7.131-8.382-17.589-7.241-25.334.705-4.801 1.812-6.828 0-12.692-1.551-5.038-10.02-11.795-11.4-17.393-1.316-5.316 6.282-12.729 3.732-22.011-1.551-5.586-10.51-8.867-10.368-18.182.087-5.931 11.736-9.079 9.874-13.942-4.504-11.77 6.15-15.366 14.967-16.974 6.221-1.142-.286-13.36 10.24-17.094 7.253-2.57 14.61 2.741 19.529.328 5.142-2.521 5.619-14.162 10.932-16.811 4.782-2.388 13.118 1.611 18.629-1.391 4.595-2.492 3.447-7.605 7.955-10.051 10.131-5.499 9.791 12.929 5.304 19.748-6.018 9.137-11.198 7.554-15.403 11.94-4.205 4.386.253 12.787-4.529 17.227-6.548 6.08-15.05 4.722-19.906 9.236-3.596 3.323 2.488 11.861-1.327 18.378-5.313 9.136-15.648 8.767-17.904 15.104-2.032 5.727 3.865 9.552 1.784 15.637-3.098 9.032-4.716 13.289-8.295 20.433-2.924 5.839-4.433 13.289-4.678 19.253-.311 6.94-2.281 22.448-1.895 24.591z"
                ></path>
                <path
                    fill="#fff"
                    d="M172.897 339.883a31.219 31.219 0 01-2.816 3.986 54.36 54.36 0 01-3.248 3.651 83.917 83.917 0 01-7.203 6.599 128.704 128.704 0 01-16.112 11.039 71.329 71.329 0 00-7.988 5.578 79.416 79.416 0 00-7.183 6.578c-4.516 4.676-8.501 9.843-12.462 14.997a71.34 71.34 0 00-2.754 4.012 54.125 54.125 0 00-2.397 4.227 56.102 56.102 0 00-3.587 9.038c-1.891 6.229-2.874 12.687-3.542 19.158-.667 6.47-.949 12.99-1.115 19.519a627.702 627.702 0 00-.183 19.544c-.116-6.516-.157-13.032-.058-19.552.1-6.521.415-13.033 1.033-19.52.618-6.487 1.601-12.974 3.475-19.241a56.63 56.63 0 013.575-9.137 60.188 60.188 0 012.401-4.277 65.83 65.83 0 012.766-4.049c3.994-5.154 8.005-10.304 12.571-14.981a78.723 78.723 0 017.241-6.578 72.68 72.68 0 018.045-5.565c2.795-1.661 5.574-3.347 8.27-5.171a127.812 127.812 0 007.879-5.768 84.358 84.358 0 007.258-6.512 56.377 56.377 0 003.28-3.613 30.448 30.448 0 002.854-3.962z"
                ></path>
                <path
                    fill="#B8EDFF"
                    d="M102.054 493.396c8.349-1.744 2.584-2.936 6.831-12.193 2.094-4.568 9.372-11.409 19.773-28.706 6.549-10.898 6.549-26.48 3.318-35.995-1.878-5.507-13.204-9.581-16.054-14.473-2.882-4.955-3.317-33.773-7.427-38.001-10.219-10.465-17.555-1.092-28.272-5.399-9.281-3.737-10.098-16.014-15.066-19.07-13.271-8.169-17.477.564-27.753-2.027-5.757-1.454-9.684-8.805-12.525-13.68-1.99-3.422-10.63-6.064-17.456.249-8.588 7.941 4.148 11.566 8.025 16.309 6.428 7.82 4.371 21.887 7.946 26.58 3.289 4.306 8.962 5.872 11.924 7.604 4.976 2.907-6.358 12.916 5.806 20.765 10.641 6.882 20.615 1.383 28.744 7.542 4.873 3.688-1.286 11.753 5.511 17.339 9.274 7.617 17.468 3.908 19.169 10.798 1.393 5.636 10.928 7.085 12.027 13.489 3.317 19.001-5.723 27.955-6.171 33.956-.498 6.545 3.193 9.369 1.65 14.913z"
                ></path>
                <path
                    fill="#DBF6FF"
                    d="M21.615 332.934c.946 1.741 1.97 3.435 3.023 5.109a131.245 131.245 0 003.273 4.946 131.894 131.894 0 007.174 9.436 110.437 110.437 0 0016.834 16.579c6.22 4.842 13.038 8.904 20.441 11.595a70.804 70.804 0 0120.454 12.148 82.43 82.43 0 0115.648 17.937 87.032 87.032 0 015.806 10.416 72.179 72.179 0 012.335 5.499 35.64 35.64 0 011.7 5.751c.745 3.93.971 7.94.672 11.928a60.276 60.276 0 01-1.995 11.749 69.71 69.71 0 01-10.115 21.417 78.288 78.288 0 005.706-10.382 68.552 68.552 0 003.899-11.164 60.08 60.08 0 001.829-11.657 45.067 45.067 0 00-.809-11.716 37.877 37.877 0 00-1.713-5.611 73.185 73.185 0 00-2.322-5.424 87.333 87.333 0 00-5.806-10.283 82.567 82.567 0 00-15.515-17.675 69.725 69.725 0 00-20.122-12.044 70.207 70.207 0 01-10.783-5.096 79.212 79.212 0 01-9.812-6.761 110.4 110.4 0 01-16.734-16.878 127.734 127.734 0 01-7.013-9.598 106.22 106.22 0 01-6.055-10.221z"
                ></path>
                <path
                    fill="#3D4663"
                    d="M93.34 500.369a1.274 1.274 0 01-.527-1.752c1.042-1.89 3.319-5.815 5.687-8.493 1.315-1.495 4.803.058 5.98-.05 2.14-.187 3.542-1.973 5.446-.092 2.683 2.658 5.337 6.463 6.656 8.427.369.557.108 1.719-.888 2.101-3.616 1.383-14.328 4.07-22.353-.141z"
                ></path>
                <path
                    fill="#E1E5FF"
                    d="M560.054 624.376c26.23 0 47.494-9.24 47.494-20.637s-21.264-20.636-47.494-20.636-47.493 9.239-47.493 20.636c0 11.397 21.263 20.637 47.493 20.637z"
                ></path>
                <path
                    fill="#242A3B"
                    d="M578.037 593.078c-4.977-2.168-6.578-3.439-13.45-4.07-2.073-.187-9.953-1.474-12.827 2.492-2.733 3.738 1.949 8.02 3.67 8.871 5.877 2.907 10.683 2.749 17.058 4.004 1.186.232 8.597 2.022 13.901-1.064 3.994-2.33 2.845-7.658.946-9.934-2.07-.531-5.263 1.45-9.298-.299z"
                ></path>
                <path
                    fill="#616B8C"
                    d="M588.351 594.798c-.299-2.65-.8-5.273-1.497-7.846-.552-1.482-4.372-3.787-12.235-2.566-2.148.336-3.554 2.076-10.181 2.571-3.604.261-11.115-1.217-12.898.976-.966 1.191-.883 2.226-.879 3.148a6.835 6.835 0 001.096 3.404 6.812 6.812 0 002.665 2.381c5.678 2.994 11.413 3.26 17.651 4.477a57.72 57.72 0 007.834.648c1.812 0 5.897-.49 7.685-3.032.813-1.175.837-3.269.759-4.161z"
                ></path>
                <path
                    fill="#242A3B"
                    d="M560.834 603.212c-5.445-2.077-7.204-3.323-14.93-3.921-2.302-.17-11.84-1.437-14.395 2.455-2.26 3.434 3.733 7.176 5.549 7.89 6.047 2.417 10.816 2.33 17.211 3.393 1.182.2 5.097 1.275 7.954 1.305 1.904 0 4.616.647 8.78-2.546 3.247-2.492 2.252-6.018.307-8.331-1.497-1.799-6.167 1.408-10.476-.245z"
                ></path>
                <path
                    fill="#7E87A8"
                    d="M571.189 602.207c-.311-1.5-1.605-5.906-1.605-5.906-.389-1.661-2.285-1.79-3.914-2.139-2.31-.486-4.023-.195-8.602-.785-2.617-.34-4.081 3.177-11.79 2.6-2.298-.17-10.368-1.636-13.45 1.869-.895 1.026-1.169 1.582-1.244 2.38v.253c.025.195.1.785.125 1.042.203 1.902 1.285 3.634 6.017 5.669 5.981 2.575 10.816 2.33 17.211 3.393 1.186.2 5.458 1.18 8.295 1.209 1.903 0 5.677-.598 8.223-3.248 2.733-2.816 1.054-4.763.734-6.337z"
                ></path>
                <path
                    fill="#BFCCD9"
                    d="M592.664 400.991c-8.183-4.693-18.323 5.507-20.044 7.891-8.24 11.445-9.343 10.959-8.734 30.732.904 29.313 2.824 61.74 3.028 72.592.249 13.04 6.001 52.69 6.221 71.665 0 1.84-.643 7.965 5.113 8.75 8.572 1.163 8.668-3.85 8.539-7.513-.104-2.952 1.601-39.915 1.116-53.188-.15-4.111-.527-14.727-1.659-23.618-.83-14.681 1.219-46.378 5.088-63.812 2.966-13.331 6.113-18.639 7.142-23.075 2.219-9.689-.722-17.509-5.81-20.424z"
                ></path>
                <path
                    fill="#F7F9FC"
                    d="M574.109 439.477c4.185-6.458 7.921-13.327 7.917-18.199 0-11.167-4.35-13.904-14.905-17.442-6.084-2.048-16.589-2.517-27.687 6.566-1.439 1.179-3.218 28.768-2.38 39.798 1.949 25.637 7.527 56.461 7.527 56.461 1.373 13.514 9.804 49.326 9.812 88.598 0 0-.506 6.474 6.001 7.512 9.738 1.562 8.709-6.727 8.709-6.727s-.257-16.879.042-34.292c.278-16.143.448-27.913-2.219-42.777-1.12-6.213 1.987-71.478 7.183-79.498z"
                ></path>
                <path
                    fill="#EDC1A6"
                    d="M599.855 346.291c-11.732-1.641-34.169 5.947-41.161 7.749-11.052 2.853-28.321 3.738-29.536 3.211-3.277-1.412-8.855-8.007-12.591-8.215-1.659-.096-5.392.573-7.685 1.607-.68.307.083 2.733 5.08 2.201 2.277-.241 3.086 1.745 2.754 2.002-4.363 3.41-12.072 1.246-12.972 2.039-2.182 1.877-1.145 5.814-.506 7.015 2.579 4.867 9.173 5.922 11.404 6.063 2.451.158 7.374.673 11.438-1.524 2.335-1.246 43.102-4.357 54.511-5.37 7.465-.664 22.084-1.906 25.543-8.339 2.729-5.071-2.712-7.928-6.279-8.439z"
                ></path>
                <path
                    fill="#EDC1A6"
                    d="M585.713 285.897c-3.815 3.293 3.853 53.574 7.05 63.226 2.945 8.879 4.832 10.121 4.832 10.121s6.565.806 9.186-4.323c2.21-4.336-5.645-58.974-6.221-60.693-3.384-9.818-10.024-12.501-14.847-8.331z"
                ></path>
                <path
                    fill="#BFCCD9"
                    d="M483.31 390.338l-45.905-25.658v-55.14l45.905 26.542v54.256z"
                ></path>
                <path
                    fill="#E6EDF7"
                    d="M541.811 302.26v54.256l-31.473 18.198-27.028 15.624v-54.256l58.501-33.822z"
                ></path>
                <path
                    fill="#AEBDCC"
                    d="M437.405 338.483l12.081 3.206-12.081-5.141v1.935zM437.405 317.315l11.62 5.071-11.62-6.67v1.599zM483.29 345.697l8.427-4.527-8.427 2.928-8.382-3.397 8.382 4.996zM483.306 378.39l-2.355-.951 2.355 1.403 2.368-1.275-2.368.823zM483.302 375.466l-3.127-1.271 3.127 1.865 3.148-1.69-3.148 1.096z"
                ></path>
                <path
                    fill="#C8D5E3"
                    d="M435.141 336.523v-.349l14.146 5.013 7.369 3.559-7.324-3.119-14.191-5.104z"
                ></path>
                <path
                    fill="#F7F9FC"
                    d="M483.31 336.082l-45.905-26.542 58.5-33.822 45.906 26.542-58.501 33.822zM435.141 336.174l2.26-1.308 11.886 6.321-14.146-5.013z"
                ></path>
                <path
                    fill="#AEBDCC"
                    d="M541.811 302.26l-56.717 33.17 1.617 1.105 55.1-30.595v-3.68zM534.748 326.51l7.063-3.771v.511l-7.063 3.26zM541.811 312.19l-17.497 7.579 17.497-5.648v-1.931z"
                ></path>
                <path
                    fill="#E6EDF7"
                    d="M521.527 320.978l22.867-9.905v-.436l-2.583.535-20.284 9.37-3.409 1.748 3.409-1.312z"
                ></path>
                <path
                    fill="#F7F9FC"
                    d="M521.527 320.542l22.868-9.905-2.584-1.529v1.628l-20.284 9.806z"
                ></path>
                <path
                    fill="#AEBDCC"
                    d="M541.811 332.611l-50.708 28.539 50.708-26.044v-2.495z"
                ></path>
                <path
                    fill="#EBF2FC"
                    d="M498.336 357.28l44.653-25.33v-.448l-44.728 25.45-10.758 6.121 10.833-5.793z"
                ></path>
                <path
                    fill="#F7F9FC"
                    d="M487.503 363.073l55.486-31.571-1.178-.569v.785l-54.308 31.355z"
                ></path>
                <path
                    fill="#E6EDF7"
                    d="M486.595 336.082v-.294l58.5-33.823v.295l-58.5 33.822z"
                ></path>
                <path
                    fill="#BFCCD9"
                    d="M486.595 336.082L440.69 309.54v-.295l45.905 26.543v.294z"
                ></path>
                <path
                    fill="#FCFDFF"
                    d="M486.595 335.788l-45.905-26.543 58.5-33.822 45.905 26.542-58.5 33.823z"
                ></path>
                <path
                    fill="#E6EDF7"
                    d="M516.886 296.828a1.22 1.22 0 01-.589-.158l-22.61-13.091a1.175 1.175 0 01-.503-.714 1.176 1.176 0 011.68-1.325l22.623 13.091a1.17 1.17 0 01.554 1.326 1.176 1.176 0 01-1.142.871h-.013zM523.107 310.072a1.16 1.16 0 01-.585-.158l-37.179-21.492a1.182 1.182 0 01.308-2.12c.29-.085.601-.057.869.081l37.188 21.492a1.17 1.17 0 01.554 1.326 1.178 1.178 0 01-1.143.871h-.012zM514.763 314.898a1.148 1.148 0 01-.593-.171l-37.18-21.496a1.178 1.178 0 01-.377-1.579 1.18 1.18 0 011.555-.46l37.175 21.496a1.176 1.176 0 01-.589 2.197l.009.013zM506.419 319.711c-.206 0-.408-.054-.585-.158l-37.18-21.492a1.178 1.178 0 01-.377-1.579c.151-.261.397-.455.686-.541.289-.085.6-.056.869.081l37.175 21.492a1.176 1.176 0 01-.588 2.197zM498.079 324.545a1.149 1.149 0 01-.589-.158l-37.176-21.496a1.178 1.178 0 01.282-2.156c.301-.081.622-.039.892.117l37.179 21.496a1.181 1.181 0 01.124 1.952 1.176 1.176 0 01-.712.245zM489.734 329.371a1.212 1.212 0 01-.588-.158l-37.184-21.496a1.178 1.178 0 01.282-2.156c.301-.081.621-.039.891.117l37.188 21.509a1.179 1.179 0 01-.589 2.197v-.013z"
                ></path>
                <path
                    fill="#FF7A00"
                    d="M590.607 375.777c-.415-1.972-.415-15.515 2.488-43.578 1.522-14.697 5.201-24.121 4.01-36.202-.257-2.616-2.227-9.083-6.565-11.757-2.364-1.458-11.612-2.754-14.54-2.122-12.981 2.803-18.086 5.851-23.929 9.435-6.499 3.987-14.801 10.416-17.319 14.611a12.915 12.915 0 00-1.161 2.546c-2.331 13.194-2.264 9.41-9.19 23.905-2.318 4.859 4.429 11.333 7.685 13.435 11.545 7.475 8.667 30.155 8.199 36.879-.548 7.891-3.733 26.704-4.305 36.028-.278 4.431 7.502 6.229 10.782 6.873a66.27 66.27 0 0027.501 0c8.609-1.786 24.402-8.468 25.526-14.241.779-3.912-7.237-26.941-9.182-35.812z"
                ></path>
                <path
                    fill="#FBD5BF"
                    d="M533.591 308.705c-1.381 6.998-4.906 39.247-4.906 55.942 0 9.361 6.017 8.655 6.017 8.655s8.71-1.794 8.859-3.164c1.136-11.052 5.607-51.112 3.877-59.501-1.75-8.518-12.578-8.348-13.847-1.932z"
                ></path>
                <path
                    fill="#FBD5BF"
                    d="M534.815 361.765s-6.578 2.11-10.887 3.684c-15.904 5.814-37.08 13.929-38.254 13.29-3.152-1.662-7.282-7.185-10.99-7.688-1.659-.224-5.416.146-7.788.993-.701.253-.133 2.782 4.889 2.6 4.085-.15 3.123 2.67 2.829 2.973-4.355 4.473-13.126-2.139-14.101-1.441-2.322 1.699 2.049 5.814 2.588 7.06 2.19 5.059 5.781 8.215 7.992 8.531 2.434.353 6.992.274 11.525-.611 3.848-.747 25.186-6.371 43.633-12.318 7.796-2.512 16.833-5.399 17.297-8.721 1.249-8.817-8.733-8.352-8.733-8.352z"
                ></path>
                <path
                    fill="#EDC1A6"
                    d="M571.675 287.637l-3.248-20.94-17.621 6.379 3.343 18.353s3.131 2.708 10.368 1.736c2.322-.308 6.884-4.299 7.158-5.528z"
                ></path>
                <path
                    fill="#FBD5BF"
                    d="M578.907 254.026c-2.264 19.881-19.57 15.566-19.466 16.808a2.799 2.799 0 01-1.858 3.044c-.776.286-1.755-.195-2.265-.548-.763-.528.568 2.491-3.276 5.723-4.077 3.43-10.132 3.231-10.783 0a35.73 35.73 0 00-2.073-6.94c-.751-1.661.116-2.596-.295-4.34-.444-1.881-2.849-2.027-2.837-3.933 0-1.562 3.733-6.541 4.148-7.667.344-.909-.913-3.509-.755-4.481 1.514-9.278 1.858-15.628 15.253-16.924 15.917-1.557 25.721 5.968 24.207 19.258z"
                ></path>
                <path
                    fill="#2F384F"
                    d="M585.46 281.021c-5.317-6.483-3.189-8.418-1.406-17.517.883-4.515 1.58-12.19.485-17.767-1.783-9.104-10.368-14.225-21.096-14.411-11.198-.2-19.741 2.795-21.852 9.207-.261.797-1.174 3.389-1.186 4.444-.319 2.076-1.203 5.179 1.186 11.213 3.504 8.863 9.696 9.432 10.53 10.507.414.548-.162-5.577.829-7.525.83-1.695 2.074-2.288 3.247-2.176 3.015.286 3.671 3.177 2.074 11.682-2.555 13.651-7.345 16.725-7.158 22.369.158 4.826 4.977 6.615 10.231 7.446 6.772 1.067 12.226.752 19.26-4.307 6.092-4.377 7.614-9.801 4.856-13.165z"
                ></path>
                <path
                    fill="#E1E5FF"
                    d="M669.988 473.258c0 1.009-.253 3.609-.763 4.618-1.053 2.077-3.152 4.116-6.1 5.815-5.188 3.015-12.488 4.747-20.027 4.747-7.54 0-14.876-1.732-20.106-4.756-3.019-1.744-4.728-3.488-5.806-5.606-.829-1.579-1.049-4.224-.697-5.511.585-2.148 2.692-4.477 6.375-6.733 5.117-3.135 12.491-4.751 20.031-4.751 7.539 0 14.871 1.736 20.101 4.76 4.446 2.583 6.988 4.282 6.992 7.417z"
                ></path>
                <path
                    fill="url(#paint5_linear_1_381)"
                    d="M670.129 388.336c-7.212-2.741-19.516-3.837-32.622-1.528-12.155 2.143-18.749 10.889-21.358 15.84l8.129 69.065c0 5.25 5.101 8.153 12.218 11.367 4.702 2.122 19.143 4.485 30.652-.37 7.241-3.056 11.139-6.86 11.139-10.893l8.124-68.941c-1.737-5.868-5.524-10.457-16.282-14.54zm-37.391 70.087c-.11.006-.221.006-.332 0a3.115 3.115 0 01-3.089-2.782l-5.134-53.67a3.112 3.112 0 015.022-2.706 3.117 3.117 0 011.161 2.041l5.134 53.67a3.12 3.12 0 01-1.599 3.083 3.1 3.1 0 01-1.163.364zm11.687-3.43h-.738c-1.659 0-2.439-1.292-2.522-2.957l-2.579-55.626a3.114 3.114 0 012.959-3.267 3.122 3.122 0 013.262 2.964l2.575 55.622a3.113 3.113 0 01-2.957 3.26v.004zm16.688-3.015a3.11 3.11 0 01-3.106 3.015h-.597c-1.717-.054-2.567-1.487-2.513-3.206l1.8-55.618a3.11 3.11 0 116.22.195l-1.804 55.614zm17.265-49.422l-5.317 53.733a3.113 3.113 0 01-3.089 2.786 3.064 3.064 0 01-.332 0 3.099 3.099 0 01-2.091-1.139 3.113 3.113 0 01-.671-2.287l5.3-53.753a3.116 3.116 0 012.199-2.744 3.1 3.1 0 013.35 1.059 3.113 3.113 0 01.635 2.341l.016.004z"
                ></path>
                <path
                    fill="#BFCCD9"
                    d="M678.287 472.519c0 1.009-.253 3.609-.767 4.618-1.05 2.077-3.148 4.116-6.097 5.814-5.188 3.016-12.487 4.747-20.027 4.747-7.539 0-14.876-1.731-20.105-4.755-3.019-1.744-5.164-3.812-6.221-5.939-.506-1.005-.78-3.738-.78-4.738 0-3.095 2.489-4.631 6.88-7.173 5.184-3.019 12.488-4.751 20.031-4.751 7.544 0 14.872 1.736 20.102 4.759 4.433 2.584 6.98 4.282 6.984 7.418z"
                ></path>
                <path
                    fill="#F7F9FC"
                    d="M678.287 471.796a6.698 6.698 0 01-.776 3.049c-1.049 2.076-3.152 4.111-6.096 5.814-5.188 3.019-12.487 4.751-20.027 4.751-7.54 0-14.88-1.732-20.11-4.759-3.015-1.745-5.159-3.809-6.22-5.935a6.752 6.752 0 01-.772-3.036c0-3.098 2.488-6.333 6.868-8.879 5.188-3.015 12.491-4.747 20.035-4.747 7.544 0 14.872 1.736 20.101 4.755 4.438 2.583 6.993 5.852 6.997 8.987z"
                ></path>
                <path
                    fill="url(#paint6_linear_1_381)"
                    d="M676.205 417.159c-13.686 7.957-35.919 7.957-49.683 0-5.674-3.277-9.021-7.401-10.037-11.666l7.793 66.22c0 5.25 5.806 8.971 11.973 11.483 4.777 1.948 19.388 4.369 30.897-.485 7.241-3.057 11.139-6.861 11.139-10.894l7.809-66.25c-.987 4.228-4.284 8.323-9.891 11.592zm-44.976 56.859a3.064 3.064 0 01-.332 0 3.109 3.109 0 01-3.09-2.787L623.1 427.13a3.123 3.123 0 011.577-3.132 3.11 3.11 0 014.607 2.468l4.707 44.105a3.112 3.112 0 01-2.762 3.447zm11.724 6.446h-.154a3.108 3.108 0 01-3.106-2.961l-2.181-44.729a3.113 3.113 0 116.22-.303l2.186 44.724a3.118 3.118 0 01-1.783 2.977 3.09 3.09 0 01-1.182.292zm16.788-2.816a3.113 3.113 0 01-3.107 3.015h-.099a3.114 3.114 0 01-3.011-3.21l1.369-43.848a3.117 3.117 0 013.207-3.018 3.118 3.118 0 013.013 3.213l-1.372 43.848zm17.559-49.421l-4.935 46.431a3.12 3.12 0 01-3.09 2.782 2.69 2.69 0 01-.336 0 3.11 3.11 0 01-2.762-3.426l4.939-46.427a3.125 3.125 0 012.199-2.744 3.106 3.106 0 013.905 2.165c.109.402.136.822.08 1.235v-.016z"
                ></path>
                <path
                    fill="#fff"
                    d="M626.521 417.154a43.906 43.906 0 0010.319 3.9 56.8 56.8 0 0010.899 1.582c1.833.084 3.67.088 5.503.03a58.066 58.066 0 005.482-.457 51.86 51.86 0 0010.68-2.492 35.257 35.257 0 009.662-5.038 21.317 21.317 0 003.907-3.738c.266-.365.556-.714.788-1.096l.369-.569.332-.586.166-.294.145-.304.29-.61.245-.627.12-.316.096-.32c.271-.858.445-1.744.518-2.641.063-.898.028-1.8-.104-2.691a13.619 13.619 0 00-1.936-5.042 19.603 19.603 0 00-3.525-4.195 30.678 30.678 0 00-4.434-3.297c3.239 1.753 6.258 4.107 8.361 7.23a14.027 14.027 0 012.144 5.2c.161.938.221 1.89.178 2.841a12.648 12.648 0 01-.481 2.811 14.994 14.994 0 01-2.596 5.034 22.03 22.03 0 01-4.019 3.966 31.752 31.752 0 01-4.752 3.007 39.603 39.603 0 01-5.147 2.218 51.755 51.755 0 01-10.89 2.491c-3.7.441-7.432.527-11.148.258a56.778 56.778 0 01-10.974-1.869 41.454 41.454 0 01-10.198-4.386z"
                ></path>
                <path
                    fill="#BFCCD9"
                    d="M676.043 389.786c-13.764-7.958-36.01-7.958-49.683 0-13.673 7.957-13.603 20.861.162 28.818 13.764 7.957 36.01 7.957 49.683 0 13.673-7.957 13.603-20.857-.162-28.818zm4.293 17.858c-1.166 2.309-3.492 4.568-6.756 6.454-5.744 3.322-13.831 5.262-22.183 5.262-8.353 0-16.477-1.919-22.267-5.271-3.342-1.931-5.719-4.219-6.905-6.574a7.48 7.48 0 01-.854-3.36c0-3.43 2.766-7.018 7.61-9.834 5.748-3.323 13.835-5.262 22.187-5.262 8.353 0 16.473 1.923 22.267 5.27 4.922 2.845 7.751 6.467 7.755 9.938a7.44 7.44 0 01-.854 3.364v.013z"
                ></path>
                <path
                    fill="#F7F9FC"
                    d="M676.043 388.336c-13.765-7.957-36.01-7.957-49.683 0-13.674 7.958-13.603 20.861.161 28.818 13.765 7.958 36.01 7.962 49.684 0 13.673-7.961 13.603-20.86-.162-28.818zm4.292 17.859c-1.165 2.309-3.492 4.568-6.755 6.453-5.744 3.323-13.831 5.262-22.184 5.262-8.352 0-16.477-1.918-22.266-5.27-3.343-1.931-5.719-4.219-6.905-6.574a7.473 7.473 0 01-.854-3.364c0-3.431 2.766-7.015 7.61-9.835 5.748-3.322 13.835-5.257 22.187-5.257 8.353 0 16.473 1.922 22.266 5.27 4.923 2.845 7.752 6.466 7.756 9.938a7.445 7.445 0 01-.855 3.364v.013z"
                ></path>
                <path
                    fill="url(#paint7_linear_1_381)"
                    d="M616.485 405.488l-.336-2.84c.001.957.114 1.91.336 2.84z"
                ></path>
                <path
                    fill="url(#paint8_linear_1_381)"
                    d="M686.096 405.547c.207-.885.313-1.791.315-2.7l-.315 2.7z"
                ></path>
            </g>
            <defs>
                <linearGradient
                    id="paint0_linear_1_381"
                    x1="384.006"
                    x2="392.64"
                    y1="489.654"
                    y2="489.654"
                    gradientUnits="userSpaceOnUse"
                >
                    <stop stopColor="#616B8C"></stop>
                    <stop offset="0.3" stopColor="#5C6686"></stop>
                    <stop offset="0.67" stopColor="#4E5876"></stop>
                    <stop offset="1" stopColor="#3D4663"></stop>
                </linearGradient>
                <linearGradient
                    id="paint1_linear_1_381"
                    x1="374.471"
                    x2="386.444"
                    y1="330.571"
                    y2="330.571"
                    gradientUnits="userSpaceOnUse"
                >
                    <stop stopColor="#616B8C"></stop>
                    <stop offset="0.3" stopColor="#5C6686"></stop>
                    <stop offset="0.67" stopColor="#4E5876"></stop>
                    <stop offset="1" stopColor="#3D4663"></stop>
                </linearGradient>
                <linearGradient
                    id="paint2_linear_1_381"
                    x1="385.673"
                    x2="513.141"
                    y1="243.095"
                    y2="243.095"
                    gradientUnits="userSpaceOnUse"
                >
                    <stop stopColor="#fff"></stop>
                    <stop offset="1"></stop>
                </linearGradient>
                <linearGradient
                    id="paint3_linear_1_381"
                    x1="54.233"
                    x2="152.675"
                    y1="539.254"
                    y2="539.254"
                    gradientUnits="userSpaceOnUse"
                >
                    <stop stopColor="#BFCCD9"></stop>
                    <stop offset="0.46" stopColor="#E6EDF7"></stop>
                </linearGradient>
                <linearGradient
                    id="paint4_linear_1_381"
                    x1="63.618"
                    x2="143.475"
                    y1="502.77"
                    y2="484.355"
                    gradientUnits="userSpaceOnUse"
                >
                    <stop offset="0.37" stopColor="#E6EDF7"></stop>
                    <stop offset="1" stopColor="#BFCCD9"></stop>
                </linearGradient>
                <linearGradient
                    id="paint5_linear_1_381"
                    x1="616.149"
                    x2="686.411"
                    y1="435.548"
                    y2="435.548"
                    gradientUnits="userSpaceOnUse"
                >
                    <stop offset="0.54" stopColor="#E6EDF7"></stop>
                    <stop offset="1" stopColor="#BFCCD9"></stop>
                </linearGradient>
                <linearGradient
                    id="paint6_linear_1_381"
                    x1="616.485"
                    x2="686.096"
                    y1="445.512"
                    y2="445.512"
                    gradientUnits="userSpaceOnUse"
                >
                    <stop stopColor="#BFCCD9"></stop>
                    <stop offset="0.33" stopColor="#E6EDF7"></stop>
                </linearGradient>
                <linearGradient
                    id="paint7_linear_1_381"
                    x1="616.149"
                    x2="616.485"
                    y1="404.068"
                    y2="404.068"
                    gradientUnits="userSpaceOnUse"
                >
                    <stop stopColor="#BFCCD9"></stop>
                    <stop offset="0.46" stopColor="#E6EDF7"></stop>
                </linearGradient>
                <linearGradient
                    id="paint8_linear_1_381"
                    x1="686.096"
                    x2="686.411"
                    y1="404.197"
                    y2="404.197"
                    gradientUnits="userSpaceOnUse"
                >
                    <stop stopColor="#BFCCD9"></stop>
                    <stop offset="0.46" stopColor="#E6EDF7"></stop>
                </linearGradient>
                <clipPath id="clip0_1_381">
                    <path fill="#fff" d="M0 0H687V692H0z"></path>
                </clipPath>
            </defs>
        </svg>
    );
}

export default BenefitsMain;
